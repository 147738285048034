import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../DeleteIcon.svg";
import PopupSaveRequisition from "./PopupSaveRequisition";
import { useSelector, useDispatch } from "react-redux";
import {
  getClient,
  getUserList,
  putRequisition,
} from "../../../../redux/action";
import { ExpandMore } from "@mui/icons-material";
import { Modal, Popover } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import AddSpoc from "./AddSpoc";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
const { URL } = require("../../../../config.json");

function PopupEditRequisition({
  requisitionData,
  operationStatusType,
  handleCloseEdit,
}) {
  let emp_id = localStorage.getItem("employeeId");
  console.log(
    "EDIT COMPONENT requisitionData",
    requisitionData,
    operationStatusType
  );
  let dispatch = useDispatch();

  let clientList = useSelector((state) => state.clientReducer.clientList);
  let divisionList = useSelector((state) => state.clientReducer.division);
  var [filteredList, setFilteredList] = useState([]);
  var [filteredList1, setFilteredList1] = useState( requisitionData.tagVendor);
  // const [filteredVender, setFilteredVender] = useState([]);
  const [vendors, setVendors] = useState([]);
  
  console.log("filteredListuf1",filteredList1)
  useEffect(() => {
    axios.get(`${URL}/bi_vender/bi_vender`)
      .then(response => {
        console.log("hgfcghc",response.data)
        setVendors(response.data);
        setFilteredList1(response.data);
      })
      .catch(error => {
        console.error('Error fetching vendors:', error);
      });
  }, []);
  
  
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setFilteredList1(searchValue);
    const filtered = vendors.filter(vendor =>
      vendor.vendorName.toLowerCase().includes(searchValue)
    );
    setFilteredList1(filtered);
  };




  const [open, setOpen] = useState(false);
  const [open10, setOpen10] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPopup = () => {
    setOpen10(true);
  };

  const handleClose10 = () => {
    setOpen10(false);
  };

  let defaultRequisition = {
    organisation_id: "",
    createdBy: "",
    bu_id: "",
    // allocationDate: formatDate(new Date()),
    clientName: "",
    clientId: "",
    positionName: "",
    location: "",
    totalPositions: "",
    addRecruiter: "",
    tat: "",
    priority: "",
    spoc: "",
    ctcOffered: "",
    accomodation: "Yes",
    educationCriteria: "",
    localWorker: "Local",
    hiring_type: "Permanent",
    instructions: "",
    totalWorkLocation: "",
    locationArray: [
      //   {
      //     locationName: { type: String },
      //     noOfPosition: { type: String },
      //     tagRecruiter: { type: String }
      // }
    ],
  };
  const [locationName, setLocationName] = useState(
    requisitionData.locationName ? requisitionData.locationName : ""
    // requisitionData?.locationArray?.locationName
  );
  const [noOfPosition, setNoOfPosition] = useState(
    requisitionData.noOfPosition ? requisitionData.noOfPosition : 0
    // requisitionData?.locationArray?.noOfPosition
  );
  const [tagRecruiter, setTagRecruiter] = useState(
    requisitionData.tagRecruiter
    // requisitionData?.locationArray?.tagRecruiter
  );
  const [tagVendor, setTagVendor] = useState(
    requisitionData.tagVendor
    // requisitionData?.locationArray?.tagRecruiter
  );

 
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [division, setDivision] = useState(
    JSON.stringify(
      divisionList.find((f) => f._id == requisitionData.divisionId)
    )
  );
  const [searchVal, setSearchVal] = useState("");
  const [searchVal1, setSearchVal1] = useState("");
  const [render, setRender] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);

  const [clientId, setClientId] = useState();

  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const handleClickPop1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClosePop1 = () => {
    setAnchorEl1(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const openPopOver1 = Boolean(anchorEl1);
  const ids = openPopOver1 ? "simple-popover" : undefined;

  const handleChangeDivision = (e) => {
    setDivision(e.target.value);
    setRequisition({
      ...requisition,
      divisionName: JSON.parse(e.target.value).divisionName,
      divisionId: JSON.parse(e.target.value)._id,
    });
  };

  const handleSetTagRecruiter = (e, row, id, i) => {
    if (e.target.checked == true) {
      tagRecruiter.push({ recruiterId: row._id, userName: row.userName });
    } else if (e.target.checked == false) {
      filteredList[i].chkd = false;
      setTagRecruiter(tagRecruiter.filter((d, i) => d.recruiterId != id));
      // setFilteredList(filteredList)
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    const isChecked = e.target.checked;
    // console.log("checked emp is", filteredList[i])

    // let objIs = filteredList[i];
    // console.log("objIs",objIs)

    // objIs = Object.assign(objIs, {chkd:e.target.checked})
    // filteredList.splice(i,1,objIs)
    // setTimeout(()=>{
    //   setRender(!render)
    // },1000)

    // if(filteredList[i].chkd== true){
    //     filteredList[i] = {...filteredList[i].chkd=false}
    // }

    // if (isChecked) {
    //   setTagRecruiter((prev) => [...prev, userName]);
    // } else {
    //   setTagRecruiter((prev) => prev.filter((item) => item !== userName));
    // }

    // console.log("tagRecruiters", tagRecruiter);
  };
  const handleSetTagVendor = (e, row, id, i) => {
    if (e.target.checked == true) {
      tagVendor.push({ vendorId: row._id, vendorName: row.vendorName });
    } else if (e.target.checked == false) {
      filteredList1[i].chkd = false;
      setTagVendor(tagVendor.filter((d, i) => d.vendorId != id));
      // setFilteredList(filteredList)
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected1(newSelected);
    const isChecked = e.target.checked;
  };




  const handleUpdateLocationArray = () => {
    setRequisition({
      ...requisition,
      locationName: locationName,
      noOfPosition: noOfPosition,
      tagRecruiter: tagRecruiter,
      locationArray: [
        {
          locationName: locationName,
          noOfPosition: noOfPosition,
          tagRecruiter: tagRecruiter,
          tagVendor: tagVendor
        },
      ],
    });
    // console.log("Updated Requsisition====", requisition);
    // let temp = {
    //   locationName: locationName,
    //   noOfPosition: noOfPosition,
    //   tagRecruiter: tagRecruiter,
    // };
    // console.log("Temp", temp, requisition);
    // setRequisition({
    //   ...requisition,
    //   locationArray: [temp, ...requisition.locationArray],
    // });
    // setTagRecruiter([]);
    // setLocationName("");
    // setNoOfPosition("");
  };
  console.log("after check value", tagRecruiter);

  useEffect(() => {
    console.log("in useEffect", filteredList);
    setFilteredList([...filteredList]);
    // setFilteredList1([...filteredList1]);
  }, [render]);

  const [requisition, setRequisition] = useState(requisitionData);

  const [spocOptions, setSpocOptions] = useState([]);
  // operationStatusType == "add" ? setRequisition(defaultRequisition) :
  // setRequisition(requisitionData)
  const [spoc, setSpoc] = useState("");
  const handleChangeRequisitionData = (e) => {
    const { name, value } = e.target;
    console.log(" handleChangeRequisitionData= ", name, value);
    setRequisition({ ...requisition, [name]: value });
    if (name == "spoc") {
      setSpoc(value);
    }
  };

  const handleChangeRequisitionClientName = (e) => {
    let clientId = e._id;
    setClientId(e._id);
    let spoc = clientList.filter((data) => {
      if (data.clientName == e.clientName) return data;
    });
    setSpocOptions(spoc != undefined ? spoc[0].spocs : []);

    setRequisition({
      ...requisition,
      clientId: clientId,
      clientName: e.clientName,
      updatedBy: emp_id
    });
  };
  const handleSubmit = () => {
    // console.log("HANDLE Update");
    if (
      requisition.positionName === "" ||
      requisition.clientName === "" ||
      requisition.locationArray.length === 0
    ) {
      alert("Please fill all the required fields");
    } else {
      requisition.updatedBy = localStorage.getItem('employeeId')
      console.log("handleChangeRequisitionClientName",requisition);
      dispatch(putRequisition(requisition));
      setRequisition(defaultRequisition);
      handleCloseEdit();
      handleClickOpen();
    }
  };

  useEffect(() => {
    dispatch(getClient());
    dispatch(getUserList());
    setRequisition({
      ...requisition,
      locationArray: [
        {
          locationName: requisitionData.locationName,
          noOfPosition: requisitionData.noOfPosition,
          tagRecruiter: requisitionData.tagRecruiter,
          tagVendor: requisitionData.tagVendor
        },
      ],
    });
  }, []);
  const UserList = useSelector((state) => state.clientReducer.userList);
  const VendorList = useSelector((state) => state.clientReducer.vendor);
  // console.log("USerList==", VendorList);
  const [clientNames, setClientNames] = useState([]);
  useEffect(() => {
    setClientNames(clientList.map((data) => data.clientName));
    // console.log(clientList);
    // setSpocOptions(
    //   clientList && clientList[0] && clientList[0].spocs
    //     ? clientList[0].spocs
    //     : []
    // )
  }, [clientList]);

  filteredList = UserList.filter((product) => {
    let searchFullName = product.userName;
    return searchFullName.toLowerCase().includes(searchVal.toLowerCase());
  });

  // filteredList1 = VendorList.filter((product) => {
  //   let searchFullName = product.vendorName;
  //   return searchFullName.toLowerCase().includes(searchVal1.toLowerCase());
  // });

  let obj = { chkd: true };

  // tagRecruiter.map((f)=>{
  //   if(UserList.find((g)=>(g._id == f.recruiterId))){
  //     g= {...g,obj}
  //   }
  // })

  for (let i = 0; i < filteredList.length; i++) {
    if (tagRecruiter.find((g) => g.recruiterId == filteredList[i]._id)) {
      filteredList[i] = { ...filteredList[i], ...obj };
      console.log(i);
    }
  }

  // for (let i = 0; i < filteredList1.length; i++) {
  //   if (tagVendor.find((g) => g.vendorId == filteredList1[i]._id)) {
  //     filteredList1[i] = { ...filteredList1[i], ...obj };
  //     console.log(i);
  //   }
  // }

  const clients = clientList.map((f) => ({
    dat: f,
    value: f.clientName,
    label: f.clientName,
  }));

  return (
    <div
      className="mainpopup"
      style={{
        fontFamily: "sans-serif",
        height: "868px",
        width: "868px",
        borderRadius: "10px",
        backgroundColor: "#ECF6FF",
        border: "1px solid #CFDFED",
        margin: "-20px -24px -20px -24px",
      }}
    >
      <Modal open={open}>
        <PopupSaveRequisition />
      </Modal>
      <div
        style={{
          backgroundColor: "#CEE3F6",
          color: "#155188",
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        EDIT REQUISITION
      </div>
      <div style={{ fontFamily: "sans-serif", margin: "0px 56px 0px 56px" }}>
        <div
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "16px",
            display: "flex",
            marginTop: "24px",
            rowGap: "8px",
            flexDirection: "column",
          }}
        >
          <div
            className="popupcontain"
            style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
          >
            {" "}
            <div
              className="popupcontain"
              style={{ display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Position Name</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="positionName"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.positionName}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "50px", display: "flex", columnGap: "17px" }}
            >
              <p style={{ marginTop: "10px" }}>Select Division</p>
              <select
                className="selectdive"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                value={division}
                onChange={handleChangeDivision}
              >
                {/* <option>Select Division</option> */}
                {divisionList.map((n) => (
                  <option value={JSON.stringify(n)}>{n.divisionName}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ display: "flex", columnGap: "44px" }}
          >
            <div
              className="popupcontain"
              style={{ marginLeft: "78px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px", width: "68px" }}>Client Name</p>
              <div
                className="popupcontain"
                style={{
                  width: "204px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                }}
              >
                <Select
                  options={clients}
                  defaultValue={[
                    clients.find((n) => n.dat._id == requisitionData.clientId),
                  ]}
                  onChange={(e) => handleChangeRequisitionClientName(e.dat)}
                />
              </div>
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>SPOC</p>
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="spoc"
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.spoc}
                variant="outlined"
              >
                {/* {console.log("SPOC ", spocOptions, requisition.spoc)} */}
                {spocOptions.map((data) => (
                  <option value={data.spocName}>{data.spocName}</option>
                ))}
                {/* <option value="">Select Here</option> */}
              </select>
              <AddCircleOutlineOutlinedIcon
                onClick={handleOpenPopup}
                fontSize="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "78px", display: "flex", columnGap: "44px" }}
          >
            <div
              className="popupcontain"
              style={{ display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Monthly CTC</p>
              <TextField
                className="popupcontaininput"
                type="number"
                size="small"
                name="ctcOffered"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.ctcOffered}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "24px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Accomodation</p>
              {/* <TextField
                size="small"
                //required={true}
                name="accomodation"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.accomodation}
                variant="outlined"
                autoComplete="off"
              /> */}
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accomodation"
                onChange={handleChangeRequisitionData}
                value={requisition.accomodation}
                variant="outlined"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "74px", display: "flex", columnGap: "44px" }}
          >
            <div
              className="popupcontain"
              style={{ display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>TAT (In Days)</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="tat"
                type="number"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.tat}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "4px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Education Criteria</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="educationCriteria"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.educationCriteria}
                variant="outlined"
                autoComplete="off"
              />
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "90px", display: "flex", columnGap: "16px" }}
          >
            <p style={{ marginTop: "10px" }}>Work Type</p>
            {/* <TextField
              size="small"
              //required={true}
              name="localWorker"
              style={{
                width: "204px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.localWorker}
              variant="outlined"
              autoComplete="off"
            /> */}
            <select
              className="popupcontaininput"
              style={{
                width: "204px",
                borderRadius: "5px",
                height: "40px",
                border: "1px solid #9A9A9A",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="localWorker"
              onChange={handleChangeRequisitionData}
              value={requisition.localWorker}
              variant="outlined"
            >
              <option value="Local">Local</option>
              <option value="Migrant">Migrant</option>
              <option value="LocalAndMigrant">Local and Migrant</option>
            </select>

            <div
              className="popupcontain"
              style={{ marginLeft: "70px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Hiring Type</p>
              {/* <TextField
              size="small"
              //required={true}
              name="positionName"
              style={{
                width: "204px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.positionName}
              variant="outlined"
              autoComplete="off"
            /> */}
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="hiring_type"
                onChange={handleChangeRequisitionData}
                value={requisition.hiring_type}
                variant="outlined"
              >
                <option value="Permanent">Permanent</option>
                <option value="Contractual">Contractual</option>
              </select>
            </div>
          </div>

          <div
            className="popupcontain"
            style={{
              marginLeft: "8px",
              marginRight: "20px",
              display: "flex",
              columnGap: "16px",
            }}
          >
            <p style={{ marginTop: "10px" }}>Any Important Instructions</p>
            <TextField
              className="popupcontaininput"
              size="small"
              //required={true}
              name="instructions"
              style={{
                width: "571px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.instructions}
              variant="outlined"
              autoComplete="off"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "sans-serif",
            justifyContent: "center",
            marginTop: "36px",
          }}
        >
          <div
            className="heightpopup"
            style={{
              width: "811px",
              height: "470px",
              backgroundColor: "#F4FAFF",
            }}
          >
            <div
              className="popupcontain"
              style={{
                color: "#525252",
                fontFamily: "sans-serif",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                margin: "0px 20px 0px 20px",
              }}
            >
              <div
                className="gapcol"
                style={{ display: "flex", columnGap: "32px" }}
              >
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Work Locations</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    1
                  </p>
                </div>
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Positions</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {requisition.noOfPosition ? requisition.noOfPosition : 0}
                    {/* {requisition.locationArray.reduce(
                      (sum, data) => sum + parseInt(data.noOfPosition),
                      0
                    )} */}
                  </p>
                </div>
              </div>
              <div
                className="popupcontain"
                style={{
                  display: "flex",
                  columnGap: "16px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <p>Location Name</p>
                  <TextField
                    className="popupcontaininput"
                    size="small"
                    //required={true}
                    name="locationName"
                    style={{
                      width: "204px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    onChange={(e) => setLocationName(e.target.value)}
                    value={locationName}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <p>No. of Position</p>
                  <TextField
                    className="popupcontaininput"
                    size="small"
                    //required={true}
                    name=""
                    type="number"
                    onChange={(e) => setNoOfPosition(e.target.value)}
                    value={noOfPosition}
                    style={{
                      width: "122px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    // onChange={}
                    // value={}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "136px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    justifyContent: "center",
                    border: "1px ridge",
                  }}
                >
                  <ExpandMore
                    style={{ marginTop: "4px", marginLeft: "-8px" }}
                    onClick={handleClickPop}
                  />
                  <p style={{ marginTop: "8px" }} onClick={handleClickPop}>
                    Tag Recruiters
                  </p>
                  <Popover
                    id={id}
                    open={openPopOver}
                    anchorEl={anchorEl}
                    onClose={handleClosePop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <TextField
                      size="small"
                      style={{
                        width: "256px",
                        borderRadius: "5px",
                        marginTop: "12px",
                        marginLeft: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              fontSize="small"
                              style={{ color: "#767676" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchVal(e.target.value)}
                      placeholder="Search Here"
                    />
                    {filteredList.map((data, index) => (
                      <ul key={data._id}>
                        <li>
                          <Checkbox
                            checked={data.chkd}
                            onChange={(e) =>
                              handleSetTagRecruiter(e, data, data._id, index)
                            }
                          />
                          {data.userName}
                        </li>
                      </ul>
                    ))}
                  </Popover>
                </div>
                {/*  */}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "136px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    justifyContent: "center",
                    border: "1px ridge",
                  }}
                >
                  <ExpandMore
                    style={{ marginTop: "4px", marginLeft: "-8px" }}
                    onClick={handleClickPop1}
                  />
                  <p style={{ marginTop: "8px" }} onClick={handleClickPop1}>
                    Tag Vendors
                  </p>
                  <Popover
                    id={ids}
                    open={openPopOver1}
                    anchorEl={anchorEl1}
                    onClose={handleClosePop1}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <TextField
                      size="small"
                      style={{
                        width: "256px",
                        borderRadius: "5px",
                        marginTop: "12px",
                        marginLeft: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              fontSize="small"
                              style={{ color: "#767676" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchVal1(e.target.value)}
                      placeholder="Search Here"
                    />
                    {filteredList1.map((data, index) => (
                      <ul key={data._id}>
                        <li>
                          <Checkbox
                            checked={data.chkd}
                            onChange={(e) =>
                              handleSetTagVendor(e, data, data._id, index)
                            }
                          />
                          {data.vendorName}
                        </li>
                      </ul>
                    ))}
                  </Popover>
                </div>
                <button
                  className="displaynone"
                  style={{
                    backgroundColor: "#4594DE",
                    color: "#FFFFFF",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "118px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "46px",
                    border: "1px ridge",
                  }}
                  onClick={handleUpdateLocationArray}
                >
                  Update Location
                </button>
              </div>
              <div
                className="fontslide"
                style={{
                  marginTop: "12px",
                  fontWeight: 550,
                  fontSize: "14px",
                }}
              >
                {" "}
                Tagged Recruiters :-
                {tagRecruiter.map((data) => (
                  <p style={{ display: "inline", fontWeight: "500" }}>
                    {" "}
                    {data.userName}{" "}
                  </p>
                ))}
              </div>
              <div
                className="fontslide"
                style={{
                  marginTop: "12px",
                  fontWeight: 550,
                  fontSize: "14px",
                }}
              >
                {" "}
                Tagged Vendors :-
                {tagVendor.map((data) => (
                  <p style={{ display: "inline", fontWeight: "500" }}>
                    {" "}
                    {data.vendorName}{" "}
                  </p>
                ))}
              </div>

              {/* mobile View */}

              <button
                className="displaymobile"
                style={{
                  backgroundColor: "#4594DE",
                  color: "#FFFFFF",
                  borderRadius: "100px",
                  cursor: "pointer",
                  width: "118px",
                  height: "32px",
                  fontFamily: "sans-serif",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginTop: "46px",
                  border: "1px ridge",
                  display: "none"
                }}
                onClick={handleUpdateLocationArray}
              >
                Update Location
              </button>

              <div className="popscrollmain" style={{}}>
                <div className="" style={{}}>
                  <Table style={{ width: "635px", marginTop: "12px" }}>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#CEE3F6" }}>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "30%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Location Name
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "30%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          No. of Position
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "40%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Recruiters
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "40%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Vendors
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>

                  <div
                    style={{
                      height: "165px",
                      overflowY: "scroll",
                      marginTop: "-8px",
                      width: "637px",
                      // marginLeft: "56px",
                      background: "#ffffff",
                    }}
                  >
                    <Table>
                      {requisition?.locationArray?.map((data, index) => (
                        <TableRow>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "30%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3%",
                            }}
                          >
                            {data.locationName}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "30%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "9%",
                            }}
                          >
                            {data.noOfPosition}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "40%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3.9%",
                            }}
                          >
                            {data.tagRecruiter.map((data) => (
                              <p
                                style={{ display: "inline", fontWeight: "500" }}
                              >
                                {" "}
                                {data.userName}{" "}
                              </p>
                            ))}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "40%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3.9%",
                            }}
                          >
                            {data.tagVendor.map((data) => (
                              <p
                                style={{ display: "inline", fontWeight: "500" }}
                              >
                                {" "}
                                {data.vendorName}{" "}
                              </p>
                            ))}
                          </TableCell>
                          {/* <TableCell
                        style={{
                          width: "5%",
                          color: "#525252",
                          textAlign: "start",
                        }}
                      >
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                          }}
                          src={DeleteIcon}
                        // onClick={() => deleteSpoc(i)}
                        />
                      </TableCell> */}
                        </TableRow>
                      ))}
                    </Table>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "14px",
                }}
              >
                <button
                  className="savebutton"
                  style={{
                    backgroundColor: "#3B82F6",
                    color: "#EDF0F6",
                    fontFamily: "sans-serif",
                    width: "56px",
                    height: "32px",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    borderRadius: "5px",
                    padding: "0px 8px 0px 8px",
                    border: "1px ridge",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open10}>
        <AddSpoc clientId={clientId} closePopup={handleClose10} />
      </Modal>
    </div>
  );
}

export default PopupEditRequisition;
